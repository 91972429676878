<template>
  <el-main>
    <el-form :model="form" label-position="top" ref="ruleForm">
      <h1>{{ title }}</h1>
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-upload class="upload-demo" ref="upload" action="#" list-type="picture-card" :auto-upload="false"
          :file-list="fileList" :on-change="handleUploadChange" :on-remove="handleUploadRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Product Code" prop="code" required
            :rules="[{ required: true, message: 'Product code is required' }]">
            <el-input v-model="form.code" placeholder="Product Code"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Product Name" prop="name" required
            :rules="[{ required: true, message: 'Product name is required' }]">
            <el-input v-model="form.name" placeholder="Product Name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="Category" prop="category" required
            :rules="[{ required: true, message: 'Category is required' }]">
            <el-select v-model="form.category" clearable placeholder="Select category" @change="onCategoryChange">
              <el-option v-for="item in _categories" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- <el-form-item label="Sub Category" prop="subCategory" required :rules="[{ required: true, message: 'Subcategory is required' }]">
            <el-select v-model="form.subCategory" clearable placeholder="Select sub category" disabled>
              <el-option v-for="item in _categories" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item> -->
          <el-form-item label="Sub Category" prop="subCategory" required
            :rules="[{ required: true, message: 'Subcategory is required' }]">
            <el-select v-model="form.subCategory" clearable placeholder="Select sub category">
              <el-option v-for="item in subCategories" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Branches" prop="branches" required
            :rules="[{ required: true, message: 'Minimum one branch is required is required' }]">
            <ElSelectAll v-model="form.branches" clearable :options="_branches" collapse-tags
              placeholder="Select branches" ref="_branches" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Colors" prop="colors" required
            :rules="[{ required: true, message: 'Minimum one color is required' }]">
            <ElSelectAll v-model="form.colors" clearable :options="_colors" filterable multiple collapse-tags
              placeholder="Select colors" ref="_colors" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="Transport Type" prop="transportType" required
            :rules="[{ required: true, message: 'Transport Type is required' }]">
            <el-select v-model="form.transportType" clearable placeholder="Select transport type">
              <el-option v-for="item in transportTypes" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Supplier Code" prop="supplierCode" required
            :rules="[{ required: true, message: 'Supplier code is required' }]">
            <el-input v-model="form.supplierCode" placeholder="Supplier Code"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="MOQ" prop="minimumOrderQuantity" required :rules="[
            { required: true, message: 'MOQ is required' },
            { type: 'number', message: 'MOQ must be a number' },
          ]">
            <el-input v-model.number="form.minimumOrderQuantity" placeholder="Minimum order quantity"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Price" prop="price" required :rules="[
            { required: true, message: 'Price is required' },
            {
              pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
              message: 'Price must be a number',
            },
            { min: 0, message: 'Price cannot be negative' },
          ]">
            <el-input v-model="form.price" placeholder="Price"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Valid till" prop="date">
            <el-date-picker v-model="form.validTill" type="date" placeholder="Pick a day" ref="_datePicker" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="3.5">
          <el-button type="primary" icon="el-icon-back" @click="$router.go(-1)">Go back</el-button>
        </el-col>
        <el-col :span="3.5">
          <el-button type="success" icon="el-icon-circle-check" @click="onSave">Save</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-main>
</template>

<script>
import { mapGetters } from "vuex";
import { TransportTypes } from "../../common/constants/TransportTypes";
import { getImage } from "../../common/utils";
import ElSelectAll from "../../components/ElSelectAll.vue";
import { saveProduct, uploadImage, updateProduct } from "./lib";
import { getSubcategoriesByCategory } from "../General/Subcategories/lib";

export default {
  name: "New_Page",
  computed: {
    ...mapGetters(["categories", "branches", "colors"]),
    _categories() {
      return [...new Set(this.categories?.map((item) => ({ value: item.id, label: item.name })))].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
    },
    _branches() {
      return [...new Set(this.branches?.map((item) => ({ value: item.id, label: item.name })))].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
    },
    _colors() {
      return [...new Set(this.colors?.map((item) => ({ value: item.id, label: item.name })))].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
    },
  },
  data() {
    return {
      title: "Add New Product",
      form: {
        code: null,
        name: null,
        transportType: null,
        category: null,
        subCategory: null,
        branches: [],
        colors: [],
        supplierCode: null,
        minimumOrderQuantity: null,
        price: null,
        validTill: null,
        imageLink: null,
      },
      editStatus: false,
      fileList: [],
      transportTypes: TransportTypes,
      isMounted: false,
      subCategories: [],
    };
  },
  methods: {
    getImage: getImage,
    async onInit() {
      if (this.$route.params.product) {
        const _data = this.$route.params.product;
        await this.onCategoryChange(_data.category.id);
        this.editStatus = true;
        this.title = "Edit Product";
        this.form.code = _data.code;
        this.form.name = _data.name;
        this.form.transportType = _data.transportType;
        this.form.category = _data.category.id;
        this.form.subCategory = _data?.subCategory?.id;
        this.form.branches = [...new Set(_data.branch.map((item) => item.id))];
        this.form.colors = [...new Set(_data.color.map((item) => item.id))];
        this.form.supplierCode = _data.supplierCode;
        this.form.minimumOrderQuantity = _data.minimumOrderQuantity;
        this.form.price = Number(_data.price);
        this.form.validTill = _data.validTill;
        this.form.imageLink = _data.imageLink;
        this.$refs._branches.selected = [...new Set(_data.branch.map((item) => item.id))];
        this.$refs._colors.selected = [...new Set(_data.color.map((item) => item.id))];
        this.fileList = [{ name: "old_image", url: getImage(_data.imageLink) }];
      }
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleUploadRemove() {
      this.fileList = [];
    },
    clearFields() {
      this.$refs._branches.clearSelections();
      this.$refs._colors.clearSelections();
      this.$refs.ruleForm.resetFields();
      this.fileList = [];
      this.form.validTill = null;
    },
    async onCategoryChange(value) {
      try {
        this.subCategories = await getSubcategoriesByCategory(value);
      } catch (error) {
        this.$notify({
          title: error.message,
          type: "error",
        });
      }
    },
    async onSave() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (!this.fileList.length) {
            this.$notify({
              title: "Please select a image for uploading",
              type: "error",
            });
            return;
          }

          await this.$store.dispatch("setLoading", true);

          if (this.fileList[0].name == "old_image") {
            // alert("old_image");
          } else {
            this.form.imageLink = await uploadImage(this.fileList[0].raw);
          }

          const _body = {
            code: this.form.code,
            name: this.form.name,
            description: this.form.description,
            price: Number(this.form.price),
            minimumOrderQuantity: this.form.minimumOrderQuantity,
            validTill: this.form.validTill,
            category: { id: this.form.category },
            subCategory: { id: this.form.subCategory },
            branch: this.form.branches.map((item) => ({ id: item })),
            color: this.form.colors.map((item) => ({ id: item })),
            supplierCode: this.form.supplierCode,
            transportType: this.form.transportType,
            imageLink: this.form.imageLink,
          };

          if (this.editStatus) {
            await this._updateProduct(_body);
          } else {
            await this.createNewProduct(_body);
          }
        } else {
          return false;
        }
      });
    },
    async createNewProduct(body) {
      try {
        await saveProduct(body);
        this.clearFields();
        this.$notify({
          title: "Product Added Successfully",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: error.message,
          type: "error",
        });
      } finally {
        await this.$store.dispatch("setLoading", false);
      }
    },
    async _updateProduct(body) {
      try {
        await updateProduct(this.$route.params.product.id, body);
        this.clearFields();
        this.$notify({
          title: "Product Updated Successfully",
          type: "success",
        });
        this.title = "Add new Product";
      } catch (error) {
        this.$notify({
          title: error.message,
          type: "error",
        });
      } finally {
        await this.$store.dispatch("setLoading", false);
      }
    },
  },

  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.onInit();
    this.isMounted = true;
    await this.$store.dispatch("setLoading", false);
  },
  components: { ElSelectAll },
};
</script>
<style></style>
