export const TransportTypes = [
  {
    value: "Air",
    label: "Air",
  },
  {
    value: "Ship",
    label: "Ship",
  },
  {
    value: "Air & Ship",
    label: "Air & Ship",
  },
];
