<template>
  <el-select v-model="selected" multiple v-bind="$attrsAll" v-on="$listenserAll" @change="onChange">
    <el-option v-for="item in mdoptionsList" :key="item.key" :label="item.label" :value="item.value" />
  </el-select>
</template>

<script>
export default {
  name: "ElSelectAll",
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    const selected = this.value || [];
    return {
      selected,
      mdoptionsValue: [],
      oldMdoptionsValue: [],
      mdoptionsList: [],
    };
  },
  computed: {
    $attrsAll() {
      // const val = this.$vnode.data.model && this.$vnode.data.model.value;
      const result = {
        // value: val,
        ...this.$attrs,
      };
      return result;
    },
    $listenserAll() {
      const _this = this;
      return Object.assign({}, this.$listeners, {
        change: () => {
          this.$emit(
            "change",
            (_this.selected || []).filter((v) => {
              return v !== "All";
            })
          );
        },
        input: () => {
          this.$emit(
            "input",
            (_this.selected || []).filter((v) => {
              return v !== "All";
            })
          );
        },
      });
    },
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit(
          "input",
          (val || []).filter((v) => {
            return v !== "All";
          })
        );
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val || val.length === 0) {
          this.mdoptionsList = [];
        } else {
          this.mdoptionsList = [
            {
              key: "All",
              value: "All",
              label: " All ",
            },
            ...val,
          ];
        }
      },
    },
  },
  mounted() {},
  methods: {
    onChange(val) {
      // eslint-disable-next-line no-debugger
      const allValues = [];
      // keep all values
      for (const item of this.mdoptionsList) {
        allValues.push(item.value);
      }
      // Used to store the last value for comparison
      const oldVal = this.oldMdoptionsValue.length === 1 ? [] : this.oldMdoptionsValue[1] || [];
      // if all selected
      if (val.includes("All")) this.selected = allValues;
      // Cancel all selected last time there is no current means cancel all selected
      if (oldVal.includes("All") && !val.includes("All")) this.selected = [];
      // Click not all selected to exclude all selected and currently clicked options
      // Both new and old data are selected
      if (oldVal.includes("All") && val.includes("All")) {
        const index = val.indexOf("All");
        val.splice(index, 1); // exclude all options
        this.selected = val;
      }
      // If all options are not selected but all other options are selected, then all options are selected. The last and current options are not selected.
      if (!oldVal.includes("All") && !val.includes("All")) {
        if (val.length === allValues.length - 1) this.selected = ["All"].concat(val);
      }
      // Store the current final result as the next old data
      this.oldMdoptionsValue[1] = this.selected;
    },
    clearSelections() {
      this.selected = [];
    },
    forceUpdate() {
      this.$forceUpdate();
    },
  },
};
</script>
