import axios from "axios";

export async function getAllSubcategories() {
  try {
    const res = await axios.get(`subcategory`);

    return convertToNestedFormat(res.data);
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

function convertToNestedFormat(data) {
  const categories = {};

  data.forEach((item) => {
    const categoryId = item.category.id;
    const categoryName = item.category.name;

    if (!categories[categoryId]) {
      categories[categoryId] = {
        id: categoryId,
        label: categoryName,
        children: [],
      };
    }

    categories[categoryId].children.push({
      id: item.id,
      label: item.name,
    });
  });

  return Object.values(categories);
}

export async function saveSubcategory(data) {
  try {
    const res = await axios.post(`subcategory`, data);

    return { id: res.data.id, label: res.data.name };
  } catch (error) {
    console.log(JSON.stringify(error));
    throw error;
  }
}

export async function removeSubcategory(id) {
  try {
    const res = await axios.delete(`subcategory/${id}`);

    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
    throw error;
  }
}

export async function getSubcategoriesByCategory(categoryId) {
  try {
    const res = await axios.get(`subcategory/categories/${categoryId}`);

    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
    throw error;
  }
}
