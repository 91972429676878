import axios from "axios";
import Vue from "vue";

async function getProducts() {
  try {
    const response = await axios.get(`product/all`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function uploadImage(data) {
  let formData = new FormData();

  formData.append("image", data, data.name);
  formData.append("path", "/product_images");

  const res = await axios.post(`storage/uploadImage`, formData);

  return res.data;
}

async function saveProduct(data) {
  try {
    const response = await axios.post(`product/create`, data);
    return { status: "Success", data: response.data };
  } catch (error) {
    console.log(error);
    throw error.response.data;
    // if (error?.response?.data?.statusCode === 400) {
    //   return { status: "Error", message: error.response.data.message[0] };
    // }
    // return { status: "Error", message: error?.response?.data };
  }
}

async function updateProduct(id, data) {
  try {
    const response = await axios.patch(`product/update/${id}`, data);
    return { status: "Success", data: response.data };
  } catch (error) {
    console.log(error?.response?.data);
    if (error?.response?.data?.statusCode === 400) {
      return { status: "Error", message: error.response.data.message[0] };
    }
    return { status: "Error", message: error?.response?.data };
  }
}

async function updateProductImage(id, imageLink) {
  try {
    let data = await axios.patch(`product/update/${id}`, { imageLink });
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function activateProduct({ id }) {
  try {
    await axios.patch(`product/makeActive/${id}`, {});
    Vue.notify({
      title: "Product Activated",
      text: "The product has been activated successfully",
      type: "success",
    });
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

async function deActivateProduct({ id }) {
  try {
    await axios.patch(`product/makeInActive/${id}`, {});
    Vue.notify({
      title: "Product Deactivated",
      text: "The product has been deactivated successfully",
      type: "success",
    });
  } catch (error) {
    console.log(error);
  }
}

async function getAllSubcategories() {
  try {
    const res = await axios.get(`subcategory`);

    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

export {
  getProducts,
  saveProduct,
  updateProduct,
  updateProductImage,
  activateProduct,
  deActivateProduct,
  uploadImage,
  getAllSubcategories,
};
